import { useState } from 'react'

import FAQ from './FAQ'

const FAQs = ({ myRef }) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <section
      ref={myRef}
      className='w-full h-max  bg-[#5935a4] py-10 md:px-16 md:py-28 overflow-hidden'
    >
      <h3 className='w-full text-center  text-[#ff7f4d] font-DelaGothic text-5xl md:text-7xl px-10 md:px-0'>
        FAQ'S
      </h3>
      <div className='flex flex-col items-center py-10 md:px-0'>
        <FAQ
          question='Do I need to pay any money to register for the Hackathon?'
          answer='No. You do not have to pay anything to anyone to register yourself for any Hackathon on HackerEarth.'
        />
        <FAQ
          question='Do I need to have any specific qualifications to be a participant for the Hackathon? '
          answer='If you love to code, you are more than welcome to participate in the Hackathon.'
        />
        <FAQ
          question='Is it necessary for all participants to belong to the same university? '
          answer='No, there are no restrictions regarding universities. Your team can have members from different universities across the globe!'
        />
        <FAQ
          question='I’m interested to participate but I am unable to find a team. What should I do? '
          answer='You can try finding like minded people on our discord server.'
        />
        <FAQ
          question='How do I submit what I have made for the Hackathon? '
          answer='You have to develop the application on your local system and submit it on HackerEarth in tar/zip file format along with instructions to run the application and source code.'
        />
        <FAQ
          question='Do we need to have the entire idea fully working? '
          answer='The entire idea need not be fully implemented however, the submission should be functional so that it can be reviewed by the judges.'
        />
        <FAQ
          question='How is the environment? Will your environment support any language? Will you provide any IDE and DB for us to work on ideas? '
          answer='You have to develop the entire software application on your local system and submit it on HackerEarth in tar/zip file format along with instructions to run the application and source code.'
        />
        <FAQ
          question='Does one have to be online and available for the entire duration of the Hackathon?'
          answer='No, one does not need to be logged in on HackerEarth or be online for the entire duration. You can develop the application on your local system based on the given themes and then submit it on HackerEarth, on the specific challenge page.'
        />
        {showMore && (
          <>
            <FAQ
              question='Since there is no specific technology mentioned, are there any restrictions on using several pre-built libraries?'
              answer='There is no restriction to use any language, technology stack, or libraries. You can use any of them to create a web/mobile application.'
            />
            <FAQ
              question='Do I need to give a demo for the product that I have built? '
              answer={
                "If you want you can submit a small presentation or video that demos your submission, however, it's not mandatory, and only good to have. In case you are one of the winners, you might be invited to demo your application details of which will be shared with sufficient advance notice."
              }
            />
            <FAQ
              question={
                "A fully developed application needs to have a solid backend for data storage like SQL, SharePoint etc. since it's online, is it accepted to show only the prototype of it? "
              }
              answer={
                "Yes, it's absolutely fine to submit just the prototype. If you use any database like MySQL or PostgresSQL you can also submit a database dump along with your submission. However, you are allowed to submit just the prototype."
              }
            />
            <FAQ
              question='If it is a team submission, does that mean all team members will have access to work at the same time? '
              answer='Yes, all team members can log in from their account and do application submission on HackerEarth.'
            />
            <FAQ
              question='Who will own the IP(Intellectual Property) Rights to the product that I have built? '
              answer='The developer/developers of the web/mobile application will have all rights and own the IP of the product. However, all code needs to be in the public domain (open source) so that it can be evaluated by the judges.'
            />
          </>
        )}

        <button
          onClick={() => setShowMore((current) => !current)}
          className='pt-10 text-[#ff7f4d] text-bold font-Sora text-lg underline underline-offset-2'
        >
          {showMore ? 'Show Less' : 'Show More'}
        </button>
        <button className='bg-[#f34c78] mt-10'>
          <a
            href='https://static.mlh.io/docs/mlh-code-of-conduct.pdf'
            target='_blank'
            rel='noreferrer'
            className='inline-flex flex-row justify-center items-center space-x-4 px-8 py-3'
          >
            <span className='font-DelaGothic text-sm sm:text-lg text-white'>
              MLH Code of Conduct
            </span>
          </a>
        </button>
        {/* <a className='pt-10 text-[#ff7f4d] text-bold font-Sora text-lg underline underline-offset-2' href='https://static.mlh.io/docs/mlh-code-of-conduct.pdf' target='_blank' rel='noreferrer'>MLH Code of Conduct</a> */}
      </div>
    </section>
  )
}

export default FAQs
