import { useMediaQuery } from 'react-responsive'

import phoneSVG from '../assets/phone.svg'
import emailSVG from '../assets/email.svg'
import instagram from '../assets/instagram.svg'
import facebook from '../assets/facebook.svg'
import twitter from '../assets/twitter.svg'
import linkedin from '../assets/linkedin.svg'

import illustration from '../assets/illus.png'

const Description = () => {
  return (
    <div className='text-[#fdf6ef] font-Sora w-full p-8 sm:p-10 pt-16 lg:p-0 lg:w-[50%] lg:pl-24 lg:py-36 lg:pb-20'>
      <h2 className='font-DelaGothic text-5xl md:text-6xl mb-10 text-[#5935a4]'>Contact</h2>
      <div className='flex flex-col justify-between text-[#47258c]'>
        <ContactCard contactName='Chanakya Vivek Kapoor' email='chanakyavkapoor@gmail.com' contactPhone='+91 81304 88585' />
        <ContactCard contactName='Shresth Tiwary' email='shresthtiwary11@gmail.com' contactPhone='+91 97429 40667' />
        <Socials />
      </div>
    </div>
  )
}

const RightImage = () => {
  return (
    <div className='w-full h-full lg:w-1/2  lg:p-10 xl:p-24 flex justify-center items-center'>
      <img src={illustration} alt='About' className='w-full lg:w-2/3' />
    </div>
  )
}

const ContactCard = ({ contactName, email, contactPhone }) => {
  return (
    <div className='flex flex-col justify-between'>
      <h4 className='font-DelaGothic text-white text-xl mb-6'>{contactName}</h4>
      <div className='inline-flex items-center mb-3 font-Sora font-bold'>
        <img src={emailSVG} alt='Mail' className='h-6 sm:h-7' /><a className='pl-2 sm:pl-4 text-sm sm:text-base' href={`mailto:${email}`}>{email}</a>
      </div>
      <div className='inline-flex items-center mb-6 font-Sora font-bold'>
        <img src={phoneSVG} alt='Phone' className='h-6 sm:h-7' /><span className='pl-2 sm:pl-4 text-sm sm:text-base'>{contactPhone}</span>
      </div>
    </div>
  )
}

const Socials = () => {
  return (
    <div className='flex flex-row justify-between items-center mt-10 w-full sm:w-[50%] lg:w-[45%]'>
      <a href='https://www.instagram.com/gdscvitvellore/' target='_blank' rel='noreferrer'>
        <img src={instagram} alt='instagram' className='h-11' />
      </a>
      <a href='https://twitter.com/gdscvit' target='_blank' rel='noreferrer'>
        <img src={twitter} alt='instagram' className='h-9' />
      </a>
      <a href='https://www.linkedin.com/company/dscvit' target='_blank' rel='noreferrer'>
        <img src={linkedin} alt='instagram' className='h-10' />
      </a>
      <a href='https://www.facebook.com/gdscvitvellore' target='_blank' rel='noreferrer'>
        <img src={facebook} alt='instagram' className='h-12' />
      </a>
    </div>
  )
}

const Contact = ({ myRef }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <section ref={myRef} className='w-full flex flex-col items-center lg:flex-row lg:h-screen overflow-hidden bg-[#ff7f4d] pb-10'>
      {isSmallScreen && (
        <>
          <RightImage />
          <Description />
        </>
      )}
      {!isSmallScreen && (
        <>
          <Description />
          <RightImage />
        </>
      )}

    </section>

  )
}
export default Contact
