import { useState } from 'react'
import upArrow from '../assets/faq-arrow-down.svg'
import downArrow from '../assets/faq-arrow-up.svg'

const FAQ = ({ question, answer }) => {
  const [expand, setExpand] = useState(false)

  return (
    <div
      className={`bg-[#fdf6ef] w-[80%] md:w-[60%] h-max cursor-pointer flex flex-col justify-between border-[6px] duration-1000 my-2 ${
        expand ? 'border-[#ff7f4d]' : 'border-white'
      }`}
    >
      <div
        onClick={() => setExpand((current) => !current)}
        className='px-4 sm:px-8 space-x-3 sm:space-x-5 py-6 flex flex-row justify-between'
      >
        <span
          className={`font-Sora ${
            expand ? 'font-bold' : ''
          } cursor-pointer w-[90%] text-xl md:text-[21px]`}
        >
          {question}
        </span>
        <img
          src={expand ? downArrow : upArrow}
          alt='.'
          className='max-w-[40px] w-[10%]'
        />
      </div>
      {expand && (
        <div className='flex flex-row justify-between pb-4 px-4 sm:px-8'>
          <span className='text-[#5935a4] font-Sora'>{answer}</span>
        </div>
      )}
    </div>
  )
}

export default FAQ
