import React from 'react'

import { useMediaQuery } from 'react-responsive'

import Marquee from '../marquee/Marquee'

import { ReactComponent as GirlIllustration } from '../assets/girl.svg'
import yearLogo from '../assets/2022.svg'
import Logo from '../assets/Logo.png'

import { ReactComponent as GDSCLogo } from '../assets/GDSC Logo.svg'
import { ReactComponent as GDSCIcon } from '../assets/GDSC Icon.svg'
import { ReactComponent as HamburgerStar } from '../assets/hamburger-star.svg'

const NewSplash = ({ navShower }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const breakLine = useMediaQuery({ query: '(min-width: 957px)' })

  return (
    <>
      <section className='w-full h-screen bg-[#e5e5e5] bg-noiseLight bg-contain bg-right bg-no-repeat flex flex-col justify-between overflow-hidden relative md:bg-auto md:bg-[#e5e5e5] md:bg-noiseLight'>
        <div className='flex flex-col overflow-hidden flex-grow justify-between py-0  md:flex-row'>
          {isSmallScreen && (
            <div className='fixed h-max max-w-[100px] min-w-[60px] w-[10%] top-28 right-[40px] sm:top-36 sm:right-[45px]'>
              {/* <img src={hamburgerMenu} onClick={() => navShower(true)} alt='menu' className='cursor-pointer' /> */}
              <HamburgerStar
                onClick={() => navShower(true)}
                alt='menu'
                className='absolute h-14 cursor-pointer top-[5px] right-[1px] animate-spin-slow z-10'
              />
              <svg
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => navShower(true)}
                className='h-7 w-7 fill-[#fdf6ef] absolute top-[18px] right-[27px] cursor-pointer z-10'
                viewBox='0 0 20 20'
                fill='white'
              >
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
          )}

          <a
            className='cursor-pointer'
            href='https://dscvit.com'
            target='_blank'
            rel='noreferrer'
          >
            {isSmallScreen
              ? (
                <GDSCIcon className='blacked h-[30px] mx-2 sm:h-9 md:h-10 lg:h-11 xl:h-12 2xl:h-13 absolute top-[6%] left-[10%] sm:left-[17%]' />
                )
              : (
                <GDSCLogo className='blacked h-[30px] sm:h-9 md:h-10 lg:h-11 xl:h-12 2xl:h-13 absolute top-[5%] left-[3%] fill-black' />
                )}
          </a>

          <div className='flex-1 w-[100%] p-5 flex flex-col items-center justify-evenly md:justify-center'>
            <div className='space-y-8 mb-4 md:w-[90%] md:space-y-0 flex flex-col items-center justify-center '>
              <div className='w-[100%] mt-20 flex justify-center md:mt-[60px] md:w-2/3 xl:w-[450px] 2xl:w-[600px]'>
                <img
                  src={Logo}
                  alt='WomenTechies'
                  className={
                    isSmallScreen
                      ? 'w-2/3 one:w-[60%] two:w-[55%] three:w-[45%] mt-20'
                      : ''
                  }
                />
              </div>
              <p className='font-Sora text-center px-4 md:px-2'>
                A 36 hour hack to ensure and promote women's participation in{' '}
                {breakLine ? <br /> : ''}tech and tech-related events
              </p>
            </div>
            <div className='w-full flex flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-10 md:items-end md:mt-10'>
              {!isSmallScreen && (
                <div className='w-[15%] h-1/2 border-t-[#ff7f4D] border-t-2' />
              )}
              <a
                href='https://www.hackerearth.com/challenges/hackathon/womentechies22-2/'
                target='_blank'
                rel='noreferrer'
              >
                <button className='bg-[#f34c78] text-white font-DelaGothic w-[1/3]  px-14 py-3'>
                  Register
                </button>
              </a>
              {!isSmallScreen && (
                <div className='w-[15%] h-1/2 border-t-[#ff7f4D] border-t-2 ' />
              )}
            </div>
            <a
              href='https://gdsc.community.dev/e/mw6ptn/'
              target='_blank'
              rel='noreferrer'
              className='underline font-DelaGothic mt-3 text-[#f34c78] text-sm text-center'
            >
              RSVP
            </a>
          </div>
          {!isSmallScreen && (
            <div className='w-[40%] overflow-hidden relative'>
              <div className='overflow-hidden'>
                <div className='h-max w-max'>
                  <img src={yearLogo} alt='2022' className='h-screen w-auto' />
                </div>
                <GirlIllustration className='absolute bottom-[-160px] w-[360px] translate-x-[140px] flex-shrink-0' />
              </div>
              {/* <div className='absolute top-28 right-[33%] xl:right-[12%] 2xl:right-[10%]'> */}
              <div className='fixed max-w-[100px] min-w-[60px] w-[10%] top-48 right-[50px] lg:top-56 lg:right-[55px] xl:top-52 xl:right-[57px]'>
                {/* <img src={hamburgerMenu} onClick={() => navShower(true)} alt='menu' className='cursor-pointer' /> */}
                <HamburgerStar
                  onClick={() => navShower(true)}
                  alt='menu'
                  className='absolute h-16 cursor-pointer top-[5px] right-[1px] animate-spin-slow z-10'
                />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  onClick={() => navShower(true)}
                  className='h-8 w-8 fill-[#fdf6ef] absolute top-[20px] right-[25px] cursor-pointer z-10'
                  viewBox='0 0 20 20'
                  fill='white'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
        <Marquee bgColor='bg-[#5935a4]' textColor='text-white' />
      </section>
    </>
  )
}

export default NewSplash
