const Sponsor = ({ image, name, website, styling }) => {
  return (
    <div className='w-[100%] sm:w-[40%] md:w-[30%] sm:mx-4 flex items-center justify-center'>
      <a className='cursor-pointer w-full flex items-center justify-center' href={website} target='_blank' rel='noreferrer'>
        <img src={image} alt={name} className={styling} />
      </a>
    </div>
  )
}

export default Sponsor
