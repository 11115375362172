import navStar from '../assets/nav-star.svg'

const Navitem = ({ text, hasStar, viewSetter, navHider }) => {
  const handleClick = () => {
    navHider()
    viewSetter()
  }
  return (
    <li onClick={handleClick} className='flex flex-row items-center w-full space-x-6 md:w-max md:px-2 lg:px-6 lg:space-x-10 xl:px-8 xl:space-x-14'>
      <a href={`#${text}`} className='text-[#ff7f4d] font-medium font-DelaGothic text-3xl py-5 sm:text-[2.5rem] sm:py-6 md:text-[2.75rem] md:py-12 lg:text-6xl lg:py-12 xl:text-7xl xl:py-12 hover:text-[#f34c78] duration-500'>
        {text}
      </a>
      {hasStar && (
        <>
          <img src={navStar} alt='.' className='h-6 sm:h-10 lg:h-12 xl:h-14 fill-white animate-spin-slow' />
        </>
      )}
    </li>
  )
}

export default Navitem
