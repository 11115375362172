import brochureArrow from '../assets/brochure-arrow.svg'
import Sponsor from './Sponsor'

import brochure from '../assets/brochure.pdf'

import agora from '../assets/agora.png'
import balsamiq from '../assets/balsamiq.png'
import cloudsek from '../assets/cloudsek.png'
import figma from '../assets/Figma-Icon.svg'
import stickerMule from '../assets/sticker-mule.svg'
import blueLearn from '../assets/blue_learn.svg'
import github from '../assets/GitHub_Logo.png'
import godaddy from '../assets/godaddy.svg'
import auth0 from '../assets/auth0.png'
import linode from '../assets/linode.png'
import twillo from '../assets/twillo.svg'
import hedera from '../assets/hedera.png'
import abinbev from '../assets/abinbev.png'

const Sponsors = ({ myRef }) => {
  return (
    <section
      ref={myRef}
      className='w-full bg-[#fdf6ef] p-10 md:p-20 flex flex-col'
    >
      <div className='w-full flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between items-center mb-10'>
        <h2 className='font-DelaGothic text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-[#5935a4]'>
          Sponsors
        </h2>
        <button className='bg-[#f34c78]'>
          <a
            href={brochure}
            download
            className='inline-flex flex-row justify-center items-center space-x-4 px-8 py-3'
          >
            <img src={brochureArrow} alt='.' className='h-4' />
            <span className='font-DelaGothic text-sm sm:text-lg text-white'>
              Brochure
            </span>
          </a>
        </button>
      </div>
      <div className='flex flex-col space-y-10 sm:flex-row flex-wrap justify-around items-center'>
        <Sponsor
          image={stickerMule}
          name='Sticker Mule'
          website='https://mule.to/p3m8'
          styling='w-[90%]'
        />
        <Sponsor
          image={cloudsek}
          name='CloudSEK'
          website='https://cloudsek.com/'
          styling='w-[70%]'
        />
        <Sponsor
          image={balsamiq}
          name='Balasmiq'
          website='https://balsamiq.com/ '
          styling='w-[60%]'
        />
        <Sponsor
          image={figma}
          name='Figma'
          website='https://www.figma.com/'
          styling='w-[15%]'
        />
        <Sponsor
          image={agora}
          name='Agora'
          website='https://agora.io'
          styling='w-[50%]'
        />
        <Sponsor
          image={blueLearn}
          name='Blue Learn'
          website='https://www.bluelearn.in/?src=gdsc-vit-vellore'
          styling='w-[60%]'
        />
        <Sponsor
          image={github}
          name='GitHub'
          website='https://www.github.com/?src=gdsc-vit-vellore'
          styling='w-[50%]'
        />
        <Sponsor
          image={auth0}
          name='Auth0'
          website='https://auth0.com/?src=gdsc-vit-vellore'
          styling='w-[45%]'
        />
        <Sponsor
          image={linode}
          name='Linode'
          website='https://www.linode.com/?src=gdsc-vit-vellore'
          styling='w-[50%]'
        />
        <Sponsor
          image={twillo}
          name='Twillo'
          website='https://www.twilio.com/?src=gdsc-vit-vellore'
          styling='w-[65%]'
        />
        <Sponsor
          image={godaddy}
          name='GoDaddy'
          website='https://www.godaddy.com/?src=gdsc-vit-vellore'
          styling='w-[55%]'
        />
        <Sponsor
          image={hedera}
          name='Hedera'
          website='https://hedera.com/'
          styling='w-[55%]'
        />
        <Sponsor
          image={abinbev}
          name='AB InBev'
          website='https://www.ab-inbev.com/'
          styling='w-[55%]'
        />
      </div>
    </section>
  )
}

export default Sponsors
