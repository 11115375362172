// import { useState } from 'react'

// import dayOne from '../assets/dayOne.png'
// import dayTwo from '../assets/dayTwo.svg'

import timeline from "../assets/timeline_wt22.png";

const Timeline = ({ myRef }) => {
  return (
    <section
      ref={myRef}
      className="bg-[#fdf6ef] overflow-auto w-full  md:h-max flex flex-col"
    >
      <h2 className="font-DelaGothic text-5xl md:text-6xl text-[#ff7f4d] p-10 lg:px-20 flex-shrink-1">
        Timeline
      </h2>

      <div className="w-[100%] pb-5 mb-12 md:w-[90%] mx-auto overflow-auto flex-grow overflow-y-hidden">
        <img
          src={timeline}
          alt="Timeline"
          className="min-w-[700px] max-w-1200px  block aspect-auto m-auto"
        />
      </div>
    </section>
  );
};

export default Timeline;
